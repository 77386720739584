.allWrap {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.wrapFirstBlock {
  background: url("../img/news.jpeg");
  background-size: cover;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.wrapHeader {
  width: calc(100% - 80px);
  height: 100px;
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid grey;
  background: #585c6c;
}
.wrapLogo {
  display: flex;
  align-items: center;
}
.logoWrap {
  margin: 0;
  font-size: 30px;
  font-family: "Lato", sans-serif;
  color: white;
  font-weight: bolder;
  margin-left: 10px;
}
.somePIn {
  font-size: 12px;
  font-family: "Lato", sans-serif;
}
.navigation {
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navigationUL {
  width: 760px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style-type: none;
}
.liForNav {
  margin-right: 5px;
  font-weight: bold;
  font-family: "Lato", sans-serif;
  font-size: 24px;
  color: white;
  cursor: pointer;
  text-decoration: none;
}
.liForNav:hover {
  color: rgb(148, 148, 170);
}
.nubberWrap {
  width: auto;
  height: auto;
  padding: 10px 30px 10px 30px;
  border: 2px solid white;
  font-family: "Lato", sans-serif;
  font-size: 30px;
  color: white;
  cursor: pointer;
  text-decoration: none;
}
.nubberWrapT {
  width: auto;
  height: auto;
  padding: 10px 50px 10px 50px;
  border: 2px solid white;
  font-family: "Lato", sans-serif;
  font-size: 30px;
  color: white;
  cursor: pointer;
  text-decoration: none;
  margin-top: 20px;
}
.wrapformWithT {
  height: calc(100vh - 100px);
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.inpWrapAll {
  width: 100%;
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.smallWrap {
  height: calc(100vh - 100px);
  width: 1200px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 80px;
}
.wrapMainText {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.wrapMainTextThank {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dispServP {
  font-family: "Lato", sans-serif;
  font-size: 100px;
  width: 50%;
  color: white;
  font-weight: bolder;
  margin-top: 0;
}
.dispServPth {
  font-family: "Lato", sans-serif;
  font-size: 100px;
  width: 80%;
  color: white;
  font-weight: bolder;
  margin-top: 0;
  text-align: center;
}
.wrapF {
  width: 50%;
  display: flex;
  align-items: center;
}
.wrapFormSend {
  background: white;
  width: calc(460px - 80px);
  height: 470px;
  padding: 20px 40px 40px 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.pForm {
  font-family: "Lato", sans-serif;
  font-size: 25px;
  font-weight: bold;
  margin-top: 0px;
  margin-bottom: 0px;
}
.wrapInp {
  height: 72px;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.labelForIn {
  font-size: 15px;
  font-family: "Lato", sans-serif;
}
.inputForm {
  width: calc(100% - 22px);
  padding-left: 20px;
  height: 40px;
  border: 1px solid rgb(148, 148, 170);
}
.buttonRequest {
  width: 200px;
  font-weight: bold;
  height: 40px;
  border: 2px solid black;
  background: none;
  color: black;
  font-size: 20px;
  font-family: "Lato", sans-serif;
  cursor: pointer;
}
.wrapSecondBlock {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.wrapTopBack {
  width: 100%;
  height: 193px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #112134;
}
.pWrFor {
  color: white;
  font-size: 50px;
  font-family: "Lato", sans-serif;
  font-weight: bolder;
}
.wrapWhoCan {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.whoCanDescription {
  color: black;
  font-family: "Lato", sans-serif;
  font-size: 20px;
  width: 700px;
  text-align: center;
}
.wrapListDry {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 20px;
}
.wrapPic {
  position: relative;
  width: 500px;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;

  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
.photoSt {
  width: 100%;
  height: 100%;
  position: relative;
  object-fit: cover;
}
.relativP {
  position: absolute;
  top: 70%;
  left: 0;
  font-family: "Lato", sans-serif;
  font-size: 50px;
  font-weight: bolder;
  color: white;
  width: 250px;
  height: 70px;
  background: black;
  display: flex;
  align-items: center;
  justify-content: center;
}
.threWrap {
  padding: 0 40px 0 40px;
  width: calc(100% - 80px);
  height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
}
.textBigWrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.bigWrapP {
  font-size: 100px;
  font-family: "Outfit", sans-serif;
  color: #343842;
  font-weight: bolder;
  width: 600px;
  text-transform: capitalize;
  margin-bottom: 5px;
}
.line {
  width: 100%;
  height: 1px;
  background: #343842;
}
.lineSmall {
  width: 100%;
  height: 1px;
  background: #343842;
}
.smallP {
  font-family: "Outfit", sans-serif;
  font-size: 25px;
  color: #343842;
  font-weight: bold;
}
.ulLi {
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  list-style-type: none;
}
.liList {
  font-family: "Outfit", sans-serif;
  font-size: 20px;
  margin-top: 10px;
  color: #343842;
}
.textSmallWr {
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 70px;
}
.procent {
  color: transparent;
  font-weight: 700;
  transition: 0.5s;
  -webkit-text-stroke-color: #333;
  -webkit-text-stroke-width: 1px;
  font-size: 95px;
  letter-spacing: 5px;
  font-family: "Bebas Neue", sans-serif;
  margin-bottom: 5px;
  margin-top: 0;
}
.procentOne {
  font-weight: 700;
  font-size: 95px;
  letter-spacing: 5px;
  font-family: "Bebas Neue", sans-serif;
  margin-bottom: 5px;
  margin-top: 0;
  color: #333;
}
.smDescP {
  font-family: "Outfit", sans-serif;
  font-size: 20px;
  font-weight: bolder;
  color: #343842;
  align-self: flex-start;
}
.aboutWrap {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.formWrap {
  width: 50%;
  height: 600px;
  background: #fbfbfb;
  display: flex;
  align-items: center;
  justify-content: center;
}
.firstBlockAbout {
  background: url("../img/fdsdgsdf.jpeg");
  width: 50%;
  height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.ourContact {
  font-family: "Outfit", sans-serif;
  font-size: 50px;
  color: white;
  font-weight: bolder;
  margin-bottom: 0;
}
.phone {
  font-family: "Outfit", sans-serif;
  font-size: 30px;
  color: white;
  font-weight: bolder;
  text-decoration: none;
  margin-top: 10px;
}
.contWr {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.footerWrap {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: #343842;
  margin-top: 50px;
}
.webUi {
  position: absolute;
  left: 0;
  bottom: 0;
  font-family: "Outfit", sans-serif;
  color: rgb(215, 214, 214);
  font-size: 15px;
  text-decoration: none;
}
.listStyle {
  width: 80%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  opacity: 1;
  color: white;
  list-style-type: none;
}
.burgerMenuWrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9); /* Напівпрозорий фон (RGBA) */
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.rxHamburgerMenu {
  font-size: 30px;
  color: white;
}
.closeBurgerMenu {
  color: white;
  position: absolute;
  font-size: 30px;
  top: 5px;
  right: 10px;
}
@media only screen and (max-width: 600px) and (min-width: 300px) {
  .textBigWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .smallP {
    font-family: "Outfit", sans-serif;
    font-size: 20px;
    color: #343842;
    font-weight: bold;
    text-align: center;
  }
  .logoWrap {
    margin: 0;
    font-size: 15px;
    font-family: "Lato", sans-serif;
    color: white;
    font-weight: bolder;
    margin-left: 0px;
  }
  .nubberWrap {
    width: auto;
    height: auto;
    padding: 5px 10px 5px 10px;
    border: 2px solid white;
    font-family: "Lato", sans-serif;
    font-size: 10px;
    color: white;
    cursor: pointer;
    text-decoration: none;
  }
  .wrapformWithT {
    height: auto;
    display: flex;

    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .smallWrap {
    min-height: auto;
    width: 300px;
    margin-top: 0;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-around;
  }
  .wrapF {
    width: 100%;
    justify-content: center;
  }
  .wrapFormSend {
    background: white;
    width: calc(300px - 40px);
    height: 300px;
    padding: 20px 20px 40px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .wrapMainText {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
  }
  .dispServP {
    font-family: "Lato", sans-serif;
    font-size: 30px;
    width: 100%;
    color: white;
    font-weight: bolder;
    margin-top: 0;
  }
  .dispServPth {
    font-family: "Lato", sans-serif;
    font-size: 30px;
    width: 100%;
    color: white;
    font-weight: bolder;
    margin-top: 0;
  }
  .wrapHeader {
    width: calc(100% - 20px);
    height: 100px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid grey;
  }

  .pForm {
    font-family: "Lato", sans-serif;
    font-size: 12px;
    font-weight: bold;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .labelForIn {
    font-size: 10px;
    font-family: "Lato", sans-serif;
  }
  .wrapInp {
    height: 60px;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .inpWrapAll {
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .pWrFor {
    font-size: 25px;
  }
  .wrapTopBack {
    width: 100%;
    height: 130px;
  }
  .whoCanDescription {
    color: black;
    font-family: "Lato", sans-serif;
    font-size: 15px;
    width: 300px;
    text-align: center;
  }
  .wrapListDry {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin-top: 20px;
  }
  .wrapPic {
    position: relative;
    width: 300px;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
  .relativP {
    position: absolute;
    top: 75%;
    left: 0;
    font-family: "Lato", sans-serif;
    font-size: 25px;
    font-weight: bolder;
    color: white;
    width: 200px;
    height: 50px;
    background: black;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .threWrap {
    padding: 0 5px 0 5px;
    width: calc(100% - 10px);
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
  .bigWrapP {
    font-size: 50px;
    font-family: "Outfit", sans-serif;
    color: #343842;
    font-weight: bolder;
    width: 300px;
    text-transform: capitalize;
    margin-bottom: 5px;
  }

  .aboutWrap {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .firstBlockAbout {
    width: 100%;
    height: 350px;
  }
  .ourContact {
    font-family: "Outfit", sans-serif;
    font-size: 25px;
    color: white;
    font-weight: bolder;
    margin-bottom: 0;
  }
  .phone {
    font-size: 15px;
  }
  .formWrap {
    width: 100%;
    height: 600px;
    background: #fbfbfb;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media only screen and (max-width: 700px) and (min-width: 479px) {
  .textBigWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .smallP {
    font-family: "Outfit", sans-serif;
    font-size: 20px;
    color: #343842;
    font-weight: bold;
    text-align: center;
  }
  .logoWrap {
    margin: 0;
    font-size: 15px;
    font-family: "Lato", sans-serif;
    color: white;
    font-weight: bolder;
    margin-left: 0px;
  }
  .nubberWrap {
    width: auto;
    height: auto;
    padding: 5px 10px 5px 10px;
    border: 2px solid white;
    font-family: "Lato", sans-serif;
    font-size: 10px;
    color: white;
    cursor: pointer;
    text-decoration: none;
  }
  .wrapformWithT {
    height: auto;
    display: flex;

    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .smallWrap {
    min-height: auto;
    width: 479px;
    margin-top: 0;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-around;
  }
  .wrapF {
    width: 100%;
    justify-content: center;
  }
  .wrapFormSend {
    background: white;
    width: calc(300px - 40px);
    height: 479px;
    padding: 20px 20px 40px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .wrapMainText {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
  }
  .dispServP {
    font-family: "Lato", sans-serif;
    font-size: 30px;
    width: 100%;
    color: white;
    font-weight: bolder;
    margin-top: 0;
  }
  .dispServPth {
    font-family: "Lato", sans-serif;
    font-size: 30px;
    width: 100%;
    color: white;
    font-weight: bolder;
    margin-top: 0;
  }
  .wrapHeader {
    width: calc(100% - 20px);
    height: 100px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid grey;
  }

  .pForm {
    font-family: "Lato", sans-serif;
    font-size: 12px;
    font-weight: bold;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .labelForIn {
    font-size: 10px;
    font-family: "Lato", sans-serif;
  }
  .wrapInp {
    height: 60px;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .inpWrapAll {
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .pWrFor {
    font-size: 25px;
  }
  .wrapTopBack {
    width: 100%;
    height: 130px;
  }
  .whoCanDescription {
    color: black;
    font-family: "Lato", sans-serif;
    font-size: 15px;
    width: 479px;
    text-align: center;
  }
  .wrapListDry {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin-top: 20px;
  }
  .wrapPic {
    position: relative;
    width: 479px;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
  .relativP {
    position: absolute;
    top: 75%;
    left: 0;
    font-family: "Lato", sans-serif;
    font-size: 25px;
    font-weight: bolder;
    color: white;
    width: 200px;
    height: 50px;
    background: black;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .threWrap {
    padding: 0 5px 0 5px;
    width: calc(100% - 10px);
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
  .bigWrapP {
    font-size: 50px;
    font-family: "Outfit", sans-serif;
    color: #343842;
    font-weight: bolder;
    width: 479px;
    text-transform: capitalize;
    margin-bottom: 5px;
  }

  .aboutWrap {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .firstBlockAbout {
    width: 100%;
    height: 350px;
  }
  .ourContact {
    font-family: "Outfit", sans-serif;
    font-size: 25px;
    color: white;
    font-weight: bolder;
    margin-bottom: 0;
  }
  .phone {
    font-size: 15px;
  }
  .formWrap {
    width: 100%;
    height: 600px;
    background: #fbfbfb;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media only screen and (max-width: 1250px) and (min-width: 1024px) {
  .smallWrap {
    height: calc(100vh - 100px);
    width: 1020px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
  }
  .logoWrap {
    margin: 0;
    font-size: 15px;
    font-family: "Lato", sans-serif;
    color: white;
    font-weight: bolder;
    margin-left: 0px;
  }
  .nubberWrap {
    width: auto;
    height: auto;
    padding: 10px 20px 10px 20px;
    border: 2px solid white;
    font-family: "Lato", sans-serif;
    font-size: 15px;
    color: white;
    cursor: pointer;
    text-decoration: none;
  }
  .liForNav {
    font-size: 15px;
  }
  .navigationUL {
    width: 650px;
  }
}
@media only screen and (max-width: 1024px) and (min-width: 701px) {
  .textBigWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .smallP {
    font-family: "Outfit", sans-serif;
    font-size: 25px;
    color: #343842;
    font-weight: bold;
    text-align: center;
  }
  .logoWrap {
    margin: 0;
    font-size: 15px;
    font-family: "Lato", sans-serif;
    color: white;
    font-weight: bolder;
    margin-left: 0px;
  }
  .nubberWrap {
    width: auto;
    height: auto;
    padding: 10px 20px 10px 20px;
    border: 2px solid white;
    font-family: "Lato", sans-serif;
    font-size: 15px;
    color: white;
    cursor: pointer;
    text-decoration: none;
  }
  .wrapformWithT {
    height: auto;
    display: flex;

    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .wrapF {
    width: 100%;
    justify-content: center;
  }

  .dispServP {
    font-family: "Lato", sans-serif;
    font-size: 50px;
    width: 100%;
    color: white;
    font-weight: bolder;
    margin-top: 0;
  }
  .dispServPth {
    font-family: "Lato", sans-serif;
    font-size: 50px;
    width: 100%;
    color: white;
    font-weight: bolder;
    margin-top: 0;
  }
  .wrapHeader {
    width: calc(100% - 20px);
    height: 100px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid grey;
  }

  .pForm {
    font-family: "Lato", sans-serif;
    font-size: 12px;
    font-weight: bold;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .labelForIn {
    font-size: 10px;
    font-family: "Lato", sans-serif;
  }
  .wrapInp {
    height: 60px;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .inpWrapAll {
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .pWrFor {
    font-size: 25px;
  }
  .wrapTopBack {
    width: 100%;
    height: 130px;
  }
  .whoCanDescription {
    color: black;
    font-family: "Lato", sans-serif;
    font-size: 15px;
    width: 479px;
    text-align: center;
  }
  .wrapListDry {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin-top: 20px;
  }
  .wrapPic {
    position: relative;
    width: 479px;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
  .relativP {
    position: absolute;
    top: 75%;
    left: 0;
    font-family: "Lato", sans-serif;
    font-size: 25px;
    font-weight: bolder;
    color: white;
    width: 200px;
    height: 50px;
    background: black;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .threWrap {
    padding: 0 5px 0 5px;
    width: calc(100% - 10px);
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
  .bigWrapP {
    font-size: 50px;
    font-family: "Outfit", sans-serif;
    color: #343842;
    font-weight: bolder;
    width: 479px;
    text-transform: capitalize;
    margin-bottom: 5px;
  }

  .aboutWrap {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .firstBlockAbout {
    width: 100%;
    height: 350px;
  }
  .ourContact {
    font-family: "Outfit", sans-serif;
    font-size: 25px;
    color: white;
    font-weight: bolder;
    margin-bottom: 0;
  }
  .phone {
    font-size: 15px;
  }
  .formWrap {
    width: 100%;
    height: 600px;
    background: #fbfbfb;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .logoWrap {
    margin: 0;
    font-size: 15px;
    font-family: "Lato", sans-serif;
    color: white;
    font-weight: bolder;
    margin-left: 0px;
  }
}
